
import Vue from 'vue';

export default Vue.extend({
  name: 'TextComponent',
  props: {
    text: {
      type: Object,
    },
    columns: {
      type: String,
    },
  },
});
